// 申請表填寫內容區
// 套件
import React, { useEffect, useState } from "react";
import axios from "axios";
// 檔案
import { API_COMMENT, API_AGREEMENT_DOWNLOAD } from "../../global/constants";
import Field from "./field";
import Canvas from "./canvas";
import "./index.css";

export default function ApplicationArea({ application, pagestate, user_role, imageURL = null, setImageURL = null, agreeID = null, setAgreeID = null, revised = null}) {
    // 判斷是否申請過，沒有申請過顯示空白申請畫面，有申請過顯示目前系統紀錄資料
    // 申請建議
    const [comment, setComment] = useState({});
    const [downloadState, setDownloadState] = useState(0);

    // 開啟介面時，先取資料
    useEffect(() => {
        if (application.commentfiles_id !== undefined) {
            getComment();
        }
    }, [application]);
    // 向後端取得申請資料建議
    const getComment = async () => {
        await axios
            .post(
                API_COMMENT,
                { commentfiles_id: application.commentfiles_id },
                { "Content-Type": "application/json" }
            ).then((res) => {
                const tmp = res.data.data;
                setComment(tmp);

            }).catch((e) => console.error(e));
    };

    const downloadAgreement = () => {
        setDownloadState(1);
    }

    const downloadSignedAgreement = async () => {
        try {
            const response = await axios.post(
                API_AGREEMENT_DOWNLOAD,
                { applications_id: application.applications_id },
                {
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    responseType: 'blob' // 將 responseType 放在這裡
                }
            );

            const fileURL = window.URL.createObjectURL(new Blob([response.data], { type: "application/pdf" }));
            const fileLink = document.createElement('a');
            fileLink.href = fileURL;
            const fileName = "Data Sharing Agreement(signed).pdf";
            fileLink.setAttribute('download', fileName);
            fileLink.setAttribute('target', '_blank');
            document.body.appendChild(fileLink);
            fileLink.click();
            fileLink.remove(); 

        } catch (error) {
            if (error.response.status === 404) {
                alert("File not found");
            } else {
                console.error('Error downloading file', error);
            }
            
        }
    }

    // 回答區渲染
    function ansArea(element) {
        if (pagestate === 1) {
            // 補件
            if (element.tag === "textarea") {
                // big textarea
                if (element.maxlength > 200) {
                    return (
                        <textarea
                            id={element.column}
                            name={element.column}
                            defaultValue={
                                application[element.column] === undefined
                                    ? ""
                                    : application[element.column]
                            }
                            className="inputArea"
                            placeholder={element.title}
                            required
                        />
                    );
                } else {
                    // small textarea
                    return (
                        <textarea
                            id={element.column}
                            name={element.column}
                            defaultValue={
                                application[element.column] === undefined
                                    ? ""
                                    : application[element.column]
                            }
                            className="smallInputArea"
                            placeholder={element.title}
                            required
                        />
                    );
                }

            } else {
                // input
                if (element.type === "radio") {
                    // radio
                    return (
                        <div className="custom-select" >
                            <select
                                id={element.column}
                                name={element.column}
                                defaultValue={
                                    application[element.column] === undefined
                                        ? ""
                                        : application[element.column]
                                }
                                required
                            >
                                <option value="" disabled>Click here</option>

                                {element.option.map((value, index) => {
                                    return (
                                        <option
                                            value={value.value}
                                            selected={
                                                application[element.column] === value.value
                                                    ? true
                                                    : false
                                            }
                                        >
                                            {value.text}
                                        </option>
                                    );
                                })}
                            </select>
                        </div>
                    );
                } else if (element.type === "number") {
                    // number input
                    return (
                        <div className="inputNumberGroup">
                            <input
                                type={element.type}
                                id={element.column}
                                name={element.column}
                                placeholder={element.title}
                                className="inputAreaNumber"
                                defaultValue={
                                    application[element.column] === undefined
                                        ? ""
                                        : application[element.column]
                                }
                                required
                            />
                            <a>months</a>
                        </div>
                    );
                } else if (element.type === "pad") {
                    return (
                        <>
                            <Canvas id={element.column}
                                name={element.column}
                                state={downloadState}
                                setState={setDownloadState}
                                imageURL={imageURL}
                                setImageURL={setImageURL}
                                agreeID={agreeID}
                                setAgreeID={setAgreeID}
                            ></Canvas>
                        </>
                    );
                } else {
                    return (
                        <div className="Agreement-group">
                            <div className="Agreement-info">
                                <a className="agree-file" href="/agreement" target="_blank">{element.filename}</a>
                                <a>5.3MB</a>
                            </div>
                            <a className="downloadBtn agreement-download" onClick={downloadAgreement}>Download</a>
                        </div>
                    );
                }
            }

        } else {
            // 審核
            if (typeof application[element.column] === "boolean") {
                return (
                    <pre className={revised!== null && revised[element.column] !== undefined && Object.keys(revised[element.column]).length > 0
                        ? "ans reviseArea" : "ans"
                    } >
                        
                        {revised !== null && revised[element.column] !== undefined && Object.keys(revised[element.column]).length > 0
                            ? <span className="revisedText">
                                {application[element.column] ? "Yes" : "No"}
                            </span>
                            : application[element.column] ? "Yes" : "No"
                        }
                        {pagestate === 2
                            ? <img
                                src={require(`../images/pencil.png`)}
                                className="comment-pencil"
                                alt="comment"
                                onClick={(e) => {
                                    showComment(e);
                                }}
                            />
                            : ""
                        }
                    </pre>
                );
            } else if (element.column === "duration") {
                return (
                    <pre className={revised !== null && revised[element.column] !== undefined && Object.keys(revised[element.column]).length > 0
                        ? "ans reviseArea" : "ans"
                    } >
                        {revised !== null && revised[element.column] !== undefined && Object.keys(revised[element.column]).length > 0
                            ? <span className="ans-sentences">
                                <span className="revisedText">
                                    {application[element.column]}
                                </span>
                                {` months` }
                            </span>
                            : <span className="ans-sentences">
                                {`${application[element.column]} months`}
                            </span>
                        }
                        
                        {pagestate === 2
                            ? <img
                                src={require(`../images/pencil.png`)}
                                className="comment-pencil"
                                alt="comment"
                                onClick={(e) => {
                                    showComment(e);
                                }}
                            />
                            : ""
                        }
                    </pre>
                );
            } else {
                return (
                    <pre className={revised !== null && revised[element.column] !== undefined && Object.keys(revised[element.column]).length > 0
                        ? "ans reviseArea" : "ans"
                    } >
                        {revised !== null && revised[element.column] !== undefined && Object.keys(revised[element.column]).length > 0
                            ? <span className="ans-sentences">
                                {showDiffAns(element.column) }
                            </span>
                            : <span className="ans-sentences">
                                {application[element.column]}
                            </span>
                        }
                        
                        {pagestate === 2
                            ? <img
                                src={require(`../images/pencil.png`)}
                                className="comment-pencil"
                                alt="comment"
                                onClick={(e) => {
                                    showComment(e);
                                }}
                            />
                            : ""
                        }
                    </pre>
                );
            }
        }
    }

    function showDiffAns(key) {

        let words = application[key].split(' ');

        return (
            //<span className="revisedText">
            //    {application[key]}
            //</span>
            words.map((el, idx) => {

                return (
                    revised[key].includes(idx)
                        ? <span className="revisedText">
                            {idx === 0 ? el : ` ${el}`}
                        </span>
                        : idx === 0 ? el : ` ${el}`
                )
            })
        );
    }

    function commentDir(element) {
        // 建議區域顯示
        if (pagestate === 2) {
            // 審核者填寫時
            return (
                <div
                    className="comment-block"
                    hidden={
                        comment === undefined ? true
                            : comment[element.column] === "" || comment[element.column] === undefined ? true : false
                    }
                >
                    <textarea
                        name={element.column}
                        className="inputArea"
                        placeholder="some advices..."
                        defaultValue={comment === undefined ? "" : comment[element.column]}
                    />
                    <div className="delcomment">
                        <button
                            className="commentDelBtn"
                            onClick={(e) => {
                                delComment(e);
                            }}
                        >
                            Delete Comment
                        </button>
                    </div>
                </div>
            );
        } else if (comment === undefined || Object.keys(comment).length === 0 || comment[element.column] === "") {
            // 結案&補件時 沒有建議
            return null;
        } else {
            // 結案&補件時 有建議
            return <pre className="comment-text">
                <span className="comment-sentences">
                    {comment[element.column]}
                </span>
            </pre>;
        }
    }

    function showComment(el) {
        if (el.target.parentElement.nextSibling !== null) {
            if (el.target.parentElement.nextSibling.hasAttribute("hidden")) {
                el.target.parentElement.nextSibling.removeAttribute("hidden");
            } else {
                el.target.parentElement.nextSibling.setAttribute("hidden", true);
            }
        }
    }

    function delComment(el) {
        el.preventDefault();
        if (el.target.parentElement.previousSibling !== null) {
            el.target.parentElement.previousSibling.value = "";
        }
        
    }

    return (
        <>
            {/* 內容 */}
            {Field.map((element) => {

                if (element.id >= 11 && user_role > 0) {
                    if (element.id === 13) {
                        // api get signed file
                        return (
                            <div key={element.id} className="column">
                                <div className="question">
                                    <label
                                        htmlFor={element.column}
                                        className={user_role === 2 ? "secondTitle text-gray" : "secondTitle"}
                                    >
                                        {element.title}
                                    </label>
                                </div>
                                {
                                    user_role === 1 ?
                                        <div className="hints-group">
                                            <div className="hints-sentences">
                                                {element.notic1}
                                                <a className="agreement-link agreement-download" href="/agreement" target="_blank"> {element.filename}</a>
                                            </div>
                                            <div className="hints-sentences">{element.notic2}</div>
                                        </div>
                                        : ""
                                }

                                <div className="signed-agreement-group">
                                    <div className="sigend-agreement-info">
                                        <a className="sigend-agree-file">{element.filename}</a>
                                        <a>5.3MB</a>
                                    </div>
                                    <div className="agreement-button-group">
                                        {pagestate === 1 ? <a className="deleteBtn ">Delete</a> : ""}
                                        <a className="downloadBtn" onClick={downloadSignedAgreement}>Download</a>
                                    </div>
                                </div>
                            </div>
                        );
                    } else {
                        return "";
                    }
                } else if (element.id === 13 && user_role === 0) {
                    return "";
                }


                return (
                    <div key={element.id} className="column">
                        {/* 題目 */}
                        <div className="question">
                            <label htmlFor={element.column}
                                className={user_role === 2 ? "secondTitle text-gray" : "secondTitle"}
                            >
                                {element.title}
                            </label>
                        </div>
                        {/* 提示 */}
                        {
                            user_role === 1 || pagestate === 1 ?
                                <div className="hints-group">
                                    {element.notic !== undefined ? <a className="hints-sentences">{element.notic}</a> : ""}

                                    {element.charcount !== undefined
                                        ? <a className="ans-len">
                                            -- {element.wordcount === undefined
                                                ? `${element.charcount} characters`
                                                : `up to ${element.charcount} characters or ${element.wordcount} words`} --
                                        </a>
                                        : ""
                                    }
                                </div>
                                : ""
                        }
                        {/* 作答 */}
                        {ansArea(element)}
                        {/* 改進 */}
                        {commentDir(element) ? commentDir(element) : ""}
                    </div>
                );
            })}
        </>
    );
}
