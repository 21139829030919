import React from "react"

export default function PaperTab({ stateNum, tablestate, setTablestate }) {
    function setShowState(arr) {
    setTablestate((prev) => {
      return {
        ...prev,
        state: {
          "pending review.": arr[0],
          "success.": arr[1],
          "To be supplemented.": arr[2],
          "fail.": arr[3],
        },
      };
    });
    }

    const unreviewCount = stateNum.unreviewed ? stateNum.unreviewed : 0;
    const reviewCount = stateNum.success + stateNum.additional + stateNum.fail ? stateNum.success + stateNum.additional + stateNum.fail : 0;


  return (
    <li>
      <button
        className={`StateTab ${
                  tablestate.state["pending review."] === true && tablestate.state["success."] === false
            ? "onStateUnreview"
            : "unState"
        }`}
        onClick={() => {
          setShowState([true, false, false, false]);
        }}
          >
              Unreviewed&emsp;<span className="hints">{unreviewCount}</span><span>&emsp;</span>
      </button>
      <button
        className={`StateTab ${
                  tablestate.state["success."] === true && tablestate.state["pending review."] === false
                      ? "onStateReview" : "unState"
        }`}
        onClick={() => {
          setShowState([false, true, true, true]);
        }}
      >
              Reviewed&emsp;<span className="hints">{reviewCount}</span><span>&emsp;</span>
      </button>
    </li>
  );
}
