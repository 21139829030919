// 套件
import React from "react";
import ReactDOM from "react-dom/client";
import { ReactKeycloakProvider } from "@react-keycloak/web";
// 檔案
import "./index.css";
import App from "./App";
import keycloak from "./global/keycloak.js";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <ReactKeycloakProvider
    authClient={keycloak}
    onTokens={({ token }) => {
      localStorage.setItem("accessToken", token);
    }}
  >
    <App />
  </ReactKeycloakProvider>
);
