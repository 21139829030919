import React from "react"
import "./index.css";

export default function Loading() {
  return (
    <div className="Allbackground loading">
      <div>Loading...</div>
    </div>
  );
}
