// �@�ɫ���
// �M��
import React from "react";
// �ɮ�
import "./index.css";
import Agreement from "./agreement"

export default function SignPDF({ }) {

    return (
        <div className="Agreement-page">
            <Agreement
                imgURL={null}
            />
        </div>
    );
}



