import { KEYCLOAK, KC_REALM, KC_CLIENT } from "./constants";
import Keycloak from "keycloak-js";

const keycloakConfig = {
  url: KEYCLOAK,
  realm: KC_REALM,
  clientId: KC_CLIENT,
};

const keycloak = new Keycloak(keycloakConfig);

export default keycloak;
