// 已建立申請案的申請檢視
// 套件
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useKeycloak } from "@react-keycloak/web";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
// 檔案
import "./index.css";
import BasicInfoArea from "../../components/basicInfoArea";
import ApplicationArea from "../../components/applicationArea";
import Field from "../../components/applicationArea/field";
import { API_CHANGE, API_APPLICATION, API_SCHEDULE, API_SYSADMIN, API_APPLICATION_REVISE } from "../../global/constants";

export default function Application() {
    // 設定keycloak
    const { keycloak } = useKeycloak();
    // 取得用戶資料
    const [userInfo, setUserInfo] = useState({});
    // 使用者是否為sysadmin
    const [sysadmin, setSysadmin] = useState();
    // 取得網址上的申請編號
    const { applications_id } = useParams();
    // 取得上一頁網址
    const [searchParams, setSearchParams] = useSearchParams();
    // 取得的紀錄資料
    const [application, setApplication] = useState({});
    // 取得申請案修改紀錄
    const [revised, setRevised] = useState({});
    // 全部僅顯示0[currently:1,3]、申請可填寫1[currently:2, 4]、審核可填寫2[currently:0]
    const [pagestate, setPagestate] = useState(0);
    // 0:apply、1:申請者、2:審核者
    const [userole, setUserole] = useState(0);
    // 進度條需要的資料
    const [scheduleline, setScheduleLine] = useState([]);
    // 所有後端取回的schedule資料
    const [scheduledata, setScheduleData] = useState({});
    // 按鈕操作 
    let option = 4;
    // 切換介面
    const navigate = useNavigate();

    // Timeline 自動捲至最右
    let timeoutID = null;
    useEffect(() => {
        if (document.getElementById("last-item") !== null && document.getElementById("last-item") !== undefined) {
            scrollLeft();
        } else {
            timeoutID = window.setInterval((() => {
                if (document.getElementById("last-item") !== null || document.getElementById("last-item") !== undefined) {
                    scrollLeft();
                }
            }), 100);
        }
    }, [document.getElementById("last-item")]);

    const scrollLeft = async () => {
        let scrollSchedule = document.getElementById("scrollSchedule");
        let scheduleItem = document.getElementsByClassName("schedule-item");
        if (scheduleItem !== undefined && scheduleItem[0] !== undefined) {
            let width = scheduleItem.length * scheduleItem[0].offsetWidth;
            scrollSchedule.scrollLeft = width;
            if (timeoutID) {
                window.clearInterval(timeoutID);
            }
        }
        
    }


    // 開啟介面時，先取資料

    // userInfo
    useEffect(() => {
        getKeycloakUserProfile();
    }, [keycloak]);
    async function getKeycloakUserProfile() {
        // loadUserProfile才有attributes屬性
        const userProfile = await keycloak.loadUserProfile();
        setUserInfo(userProfile);
    }

    // 使用者是否為sysadmin
    useEffect(() => {
        if (userInfo.email !== undefined) {
            getSysadmin();
        }
    }, [userInfo]);
    async function getSysadmin() {
        await axios
            .get(API_SYSADMIN)
            .then((res) => {
                const sysadmins = res.data.data;
                if (sysadmins.includes(userInfo.email)) {
                    setSysadmin(true);
                } else {
                    setSysadmin(false);
                }
            })
            .catch((e) => {
                console.error(e);
            });
    }

    // application
    useEffect(() => {
        if (applications_id !== undefined) {
            getApplication();
        }
    }, [applications_id]);
    // 向後端取得申請資料
    const getApplication = async () => {
        await axios
            .post(
                API_APPLICATION,
                { applications_id: applications_id },
                { "Content-Type": "application/json" }
            )
            .then((res) => {
                let tmp = res.data.data;
                if (application.datasets_maintainer_email === "unknown") {
                    alert("There's some problem appeared.");
                    navigate("/");
                } else {
                    setApplication(() => { return { ...tmp, applications_id: applications_id } });
                }

            })
            .catch((e) => console.error(e));
    };

    // 取得申請資料後判斷要顯示哪種版面
    useEffect(() => {
        if (sysadmin !== undefined && userInfo.email !== undefined && application.datasets_maintainer_email !== undefined) {
            const tmpState = application.currently;
            if (
                application.datasets_maintainer_email !== userInfo.email &&
                application.applicant_mail !== userInfo.email &&
                application.author_email !== userInfo.email &&
                !sysadmin
            ) {
                alert("You do not have access rights.");
                navigate("/");
            } else if (
                tmpState === 0 &&
                application.datasets_maintainer_email === userInfo.email
            ) {
                //待審核時的管理者畫面
                setPagestate(2);
            } else if (
                (tmpState === 2 || tmpState === 4) &&
                application.applicant_mail === userInfo.email
            ) {
                // 被要求補件時的申請者畫面
                setPagestate(1);
            } else {
                setPagestate(0);
            }

            if (application.applicant_mail === userInfo.email) {
                setUserole(1);
            } else {
                setUserole(2);
            }
        }
    }, [application, sysadmin]);

    // 更新介面狀態到application使子物件可以共享狀態
    useEffect(() => {
        setApplication((prev) => {
            return { ...prev, pagestate: pagestate };
        });
        // 若為檢視狀態，取得申請資料修改紀錄
        if (pagestate === 2) {
            getApplicationRevise();
        } else {
            setRevised(null);
        }
    }, [pagestate]);

    // 向後端取得申請案修改紀錄
    const getApplicationRevise = async () => {
        await axios
            .post(
                API_APPLICATION_REVISE,
                { applications_id: applications_id },
                { "Content-Type": "application/json" }
            )
            .then((res) => {
                let tmp = res.data.data;
                if (Object.keys(tmp).length === 0) {
                    setRevised(null);
                } else {
                    setRevised(tmp);
                }
                
            })
            .catch((e) => console.error(e));
    };

    // 取得schedule
    useEffect(() => {
        if (userole != 0) {
            getSchedule();
        }
    }, [userole]);
    // 向後端取得schedule
    const getSchedule = async () => {
        await axios
            .post(
                API_SCHEDULE,
                { applications_id: applications_id, userole: userole },
                { "Content-Type": "application/json" }
            )
            .then(async (res) => {
                setScheduleLine(res.data.data.item);
                setScheduleData(res.data.data);
            })
            .catch((e) => console.error(e));
    };

    // 送出更改請求
    const change = async (data) => {
        await axios
            .post(API_CHANGE, data, { "Content-Type": "application/json" })
            .then((res) => {
                alert(`Application ${applications_id} was changed successfully.`);
                window.location.href = `/`;
            })
            .catch((e) => console.error(e));
    };

    async function submitFun(e) {
        /*
         * 按鈕操作option
         * 4:暫存
         * 0:送出補件 insert records insert applicationfiles
         * 3:拒絕補件 insert records
         * 3:拒絕 insert records
         * 1:成功 insert records
         * 2:要求補件 insert records inser commentfiles
         */
        // 建立送出的資料
        let data = {};
        // 是否確認送出
        let submit;
        // 資料是否有變更
        let dataChanged = false;

        e.preventDefault();

        const form = e.target;
        const formData = new FormData(form);
        data = Object.fromEntries(formData.entries());

        const inputField = Field.map((el) => {
            if (el.id >= 2 && el.id <= 10) {
                return el.column;
            }
        }).filter((ele) => ele !== undefined);

        for (let val of inputField) {
            if (data[val] !== application[val].toString()) {
                dataChanged = true;
                break;
            }
        }

        // 是否有填入表格
        let dict_values = Object.values(data);
        let emptyStr = (val) => val === "";

        // 若為 審核者 但 沒有填建議
        if ((pagestate === 2 && dict_values.every(emptyStr))) {
            alert(option === 1
                ? "Please give some comment."
                : option === 2
                    ? "Please suggest some modifications."
                    : "Please enter at least one reason for rejection."
            );
        } else {
            // 申請失敗按鈕 需重複確認
            if (option === 3) {
                submit = window.confirm(userole === 1
                    ? "Are you sure you want to abandon your application?"
                    : "Are you certain you want to reject this application?");
                // 1:使用者、2:審核者
                data.userole = userole;
                if (submit && userole == 1) {
                    data.reason = "";
                    while (data.reason === "") {
                        data.reason = window.prompt("Please provide the reason for cancelling your application:");
                    }
                }

            // 申請者送出時 資料未變更
            } else if (pagestate === 1 && option === 0) {
                if (!dataChanged) {
                    submit = window.confirm(`The application information hasn't been modified and it might still be rejected.\nAre you sure you want to submit it anyway?`);
                } else {
                    submit = true;
                }
            // 審核者退件時 Comment未更動
            } else if (pagestate === 2 && option === 2) {
                if (!dataChanged) {
                    submit = window.confirm(`Your feedback hasn't been modified.\nAre you certain you want to send back the application for the same reason?`);
                } else {
                    submit = true;
                }
            } else {
                submit = true
            }

            if (submit) {
                // table records info
                data.applications_id = applications_id;
                data.applicationfiles_id = application.applicationfiles_id;
                data.commentfiles_id = application.commentfiles_id;
                data.currently = option;
                data.data_changed = dataChanged;

                // 寄送email通知用的
                data.datasets_id = application.datasets_id;
                data.datasets_title = application.datasets_title;
                data.datasets_maintainer_email = application.datasets_maintainer_email;
                data.project_title = application.title;
                // 申請者資訊
                data.applicant_name = application.users_name;
                data.applicant_email = application.applicant_mail;
                data.applicant_unit = application.users_unit;
                data.applicant_jobtitle = application.users_jobtitle;

                // 更改資料
                await change(data);
                //console.log(data);
            }
        }
    }
    function btnArea() {
        if (pagestate === 1) {
            // 申請者補件
            return (
                <>
                    <button
                        className="Button Save"
                        type="submit"
                        id="form-save"
                        onClick={() => {
                            option = 4;
                        }}
                    >
                        Reject
                    </button>
                    <button
                        className="Button Submit"
                        type="submit"
                        id="form-submit"
                        onClick={() => {
                            option = 0;
                        }}
                    >
                        Submit
                    </button>
                    <button
                        className="Button Reject"
                        type="submit"
                        id="form-reject"
                        onClick={() => {
                            option = 3;
                        }}
                    >
                        Reject
                    </button>
                </>
            );
        } else if (pagestate === 2) {
            // 審核者審核
            return (
                <>
                    <button
                        className="Button Success"
                        type="submit"
                        id="form-approved"
                        onClick={() => {
                            option = 1;
                        }}
                    >
                        Approve
                    </button>
                    <button
                        className="Button Reject"
                        type="submit"
                        id="form-reject"
                        onClick={() => {
                            option = 3;
                        }}
                    >
                        Reject
                    </button>
                    <button
                        className="Button Return"
                        type="submit"
                        id="form-send-back"
                        onClick={() => {
                            option = 2;
                        }}
                    >
                        Send&ensp;back
                    </button>
                </>
            );
        } else {
            // 狀態為僅檢視時
            if (application.currently !== 1 && userole === 1) {
                // 非成功 (待審核、待補件、暫存) 且 為申請者查看
                return (
                    <>
                        <button
                            className="Button Reject"
                            type="submit"
                            id="form-reject"
                            onClick={() => {
                                option = 3;
                            }}
                        >
                            Reject
                        </button>
                    </>
                );

            } else {
                return null;
            }
        }
    }

    function showBtn() {
        if (pagestate === 1) {
            // 申請者補件
            return (
                <>
                    <button type="button"
                        id="application_reset"
                        className="barBtn btn-outline-gray"
                        onClick={() => { document.querySelector('#form-reset').click(); }}
                    >
                        Reset
                    </button>
                    <button type="button"
                        id="application_send_back"
                        className="barBtn btn-outline-dark"
                        onClick={() => { document.querySelector('#form-save').click(); }}
                    >
                        Save
                    </button>
                    <button type="button"
                        id="application_submit"
                        className="barBtn btn-outline-success"
                        onClick={() => { document.querySelector('#form-submit').click(); }}
                    >
                        Submit
                    </button>
                    <button type="button"
                        id="application_reject"
                        className="barBtn btn-outline-warn"
                        onClick={() => { document.querySelector('#form-reject').click(); }}
                    >
                        Withdraw
                    </button>
                </>
            );
        } else if (pagestate === 2) {
            // 審核者審核
            return (
                <>
                    <button type="button"
                        id="application_reset"
                        className="barBtn btn-outline-gray"
                        onClick={() => { document.querySelector('#form-reset').click(); }}
                    >
                        Reset
                    </button>
                    <button type="button"
                        id="application_reject"
                        className="barBtn btn-outline-warn"
                        onClick={() => { document.querySelector('#form-reject').click(); }}
                    >
                        Reject
                    </button>
                    <button type="button"
                        id="application_send_back"
                        className="barBtn btn-outline-dark"
                        onClick={() => { document.querySelector('#form-send-back').click(); }}
                    >
                        Send&ensp;back
                    </button>
                    <button type="button"
                        id="application_approved"
                        className="barBtn btn-outline-success"
                        onClick={() => { document.querySelector('#form-approved').click(); }}
                    >
                        Approved
                    </button>
                </>
            );
        } else {
            // 狀態為僅檢視時
            if (application.currently === 3 && userole === 1) {
                // 失敗 且 為申請者查看
                return (
                    <>
                        <button type="button"
                            id="application_reject"
                            className="barBtn btn-outline-success"
                            onClick={() => {
                                if (window.confirm("Your previous application was rejected. Please review the rejection reasons to avoid another rejection. Do you want to proceed with the reapplication?")) {
                                    navigate(`/apply/${application.datasets_name}?redirect_uri=/`);
                                }
                            }}
                        >
                            Reapply
                        </button>
                    </>
                );
            } else if (application.currently !== 1 && userole === 1) {
                // 非成功 (待審核、待補件、暫存) 且 為申請者查看
                return (
                    <>
                        <button type="button"
                            id="application_reject"
                            className="barBtn btn-outline-warn"
                            onClick={() => { document.querySelector('#form-reject').click(); }}
                        >
                            Withdraw
                        </button>
                    </>
                );

            } else {
                return null;
            }
            
        }
    }

    function hintsArea() {
        if (userole == 2) {
            return (
                <>
                    You are reviewing ID #{application.id}
                    <span className="hints-gray">from {application.applicant_mail}</span>
                </>
            );
        } else if (userole == 1) {
            if (application.currently == 0) {
                return (
                    <>
                        Application is under reviewed.
                    </>
                );
            } else if (application.currently == 1) {
                return (
                    <>
                        Application is approved.
                    </>
                );
            } else if (application.currently == 2 || application.currently == 4) {
                return (
                    <>
                        Supplements are required to complete your application.
                        <span className="hints-gray">
                            Please check out the
                            <a className="hints-comment">comment</a>.
                        </span>
                    </>
                );
            } else if (application.currently == 3) {
                return (
                    <>
                        Application is rejected.
                    </>
                );
            }
        }
    }

    function scheduleArea(scheduleline) {
        return (
            <>
                {scheduleline.map((el, idx) => {

                    return (
                        <>
                            {
                                scheduleline.length - 1 > idx
                                    ? <div className="schedule-item">
                                        <img src={require(`../../components/images/green-dot-line.png`)} className="timeline-icon" />
                                        <a>{el.cardTitle}</a>
                                        <a>{el.title}</a>
                                    </div>
                                    : pagestate == 0
                                        ? application.currently == 0
                                            ? <>
                                                <div className="schedule-item">
                                                    <img src={require(`../../components/images/green-dot-gray-line.png`)} className="timeline-icon" />
                                                    <a>{el.cardTitle}</a>
                                                    <a>{el.title}</a>
                                                </div>
                                                <div className="schedule-item" id="last-item">
                                                    <img src={require(`../../components/images/gray-period.png`)} className="timeline-icon timeline-dot" />
                                                    <a>
                                                        Pending review
                                                    </a>
                                                </div>
                                            </>
                                            : <div className="schedule-item" id="last-item">
                                                <img src={require(`../../components/images/green-dot.png`)} className="timeline-icon timeline-dot" />
                                                <a>{el.cardTitle}</a>
                                                <a>{el.title}</a>
                                            </div>
                                        : <>
                                            <div className="schedule-item">
                                                <img src={require(`../../components/images/green-dot-gray-line.png`)} className="timeline-icon" />
                                                <a>{el.cardTitle}</a>
                                                <a>{el.title}</a>
                                            </div>
                                            <div className="schedule-item" id="last-item">
                                                <img src={require(`../../components/images/gray-period.png`)} className="timeline-icon timeline-dot" />
                                                <a>
                                                    {userole == 1 ? "Resubmit" : "Pending review"}
                                                </a>
                                            </div>
                                        </>
                            }
                        </>
                    );

                })}
            </>
        );
    }


    return (
        <div className="Allbackground">
            <div className="hints-bar">
                <div className="hints-text">
                    {hintsArea()}
                </div>
                <div className="hints-btn">
                    {showBtn()}
                    <button type="button"
                        id="application_close"
                        className="barBtn btn-borderless-light"
                        onClick={() => { document.querySelector('#form-close').click(); }}
                    >
                        x Close
                    </button>
                </div>
            </div>

            <div className="apply-card">
                <div className="apply-card-content">

                    {/*schedule*/}
                    <div className="schedule-timeline" id="scrollSchedule">
                        {scheduleArea(scheduleline)}
                    </div>
                    {/*application form*/}
                    <form onSubmit={submitFun} className="Applyform" >
                        {/* 內容 */}
                        <div className="paper">
                            <BasicInfoArea application={application} user_role={userole} />

                            <ApplicationArea application={application} pagestate={pagestate} user_role={userole} revised={revised} />
                        </div>
                        {/* 按鈕 */}
                        <div className="ButtonGroup">
                            {btnArea()}
                            <button
                                type="reset"
                                className="Button Reset"
                                id="form-reset"
                                hidden={pagestate === 0 ? true : false}
                            >
                                Reset
                            </button>
                            <button
                                type="button"
                                className="Button Close"
                                id="form-close"
                                onClick={() => {
                                    navigate(`/?tab=${searchParams.get("tab") ? searchParams.get("tab") : 0}`);
                                }}
                            >
                                Close
                            </button>
                        </div>
                    </form>
                </div>
            </div>

        </div>
        //<form onSubmit={submitFun} className="Allbackground">
        //  {/* 內容 */}
        //  <div className="paper">
        //    <BasicInfoArea application={application} />
        //    <hr />
        //    <ApplicationArea application={application} pagestate={pagestate} />
        //  </div>
        //  {/* 按鈕 */}
        //  <div className="ButtonGroup">
        //    {btnArea()}
        //    <button
        //      type="reset"
        //      className="Button Reset"
        //      hidden={pagestate === 0 ? true : false}
        //    >
        //      Reset
        //    </button>
        //    <button
        //      type="button"
        //      className="Button Close"
        //      onClick={() => {
        //        navigate("/");
        //      }}
        //    >
        //      Close
        //    </button>
        //  </div>
        //</form>
    );
}
