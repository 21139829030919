// 首次申請
// 套件
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { useKeycloak } from "@react-keycloak/web";
// download html as pdf
import jsPDF from 'jspdf'
// 檔案
import "./index.css";
import {
    API_APPLY,
    API_DATASETSINFO,
    API_DEFAULT,
    API_APPLICATION_RECORDS,
    API_SYSADMIN,
    ACCOUNT_INDEX,
    CKAN_HOST,
} from "../../global/constants";
import ApplicationArea from "../../components/applicationArea";
import BasicInfoArea from "../../components/basicInfoArea";

// 送出時候需要驗證欄位是否符合要求
export default function Apply() {
    // keycloak
    const { keycloak } = useKeycloak();
    // 介面跳轉套件
    const navigate = useNavigate();
    // 申請的資料集名字
    const { datasets_name } = useParams();
    // 取得上一頁網址
    const [searchParams, setSearchParams] = useSearchParams();
    // 取得用戶資料
    const [userInfo, setUserInfo] = useState({});
    // 要建立申請的所有資料
    const [application, setApplication] = useState({ pagestate: 1 });
    // 是否為sysadmin
    const [sysadmin, setSysadmin] = useState();
    // 簽名檔
    const [imageURL, setImageURL] = useState(null);
    const [blob, setBlob] = useState(null);
    const [agreeID, setAgreeID] = useState("signature");

    // 當keycloak變動時，取得使用者資料
    useEffect(() => {
        getKeycloakUserProfile();
    }, [keycloak]);
    // 取得使用者keycloak內使用者資料
    async function getKeycloakUserProfile() {
        // loadUserProfile才有attributes屬性
        const userProfile = await keycloak.loadUserProfile();
        setUserInfo(userProfile);
    }
    // 使用者資料變動後判斷使用者是否為系統管理員  取得平台資料庫的使用者資料
    useEffect(() => {
        if (userInfo.email !== undefined) {
            getSysadmin();
        }
    }, [userInfo]);

    // 判斷使用者是否為系統管理員
    async function getSysadmin() {
        await axios
            .get(API_SYSADMIN)
            .then((res) => {
                const sysadmins = res.data.data;
                if (sysadmins.includes(userInfo.email)) {
                    // 防止系統管理員申請資料集
                    alert("You do not need to apply for this dataset!");
                    window.location.replace(searchParams.get("redirect_uri") ? searchParams.get("redirect_uri") : CKAN_HOST);
                } else {
                    // 取得平台資料庫的使用者資料
                    getUserInfo();
                    setSysadmin(false);
                }
            })
            .catch((e) => {
                console.error(e);
            });
    }

    // 取得平台資料庫的使用者資料
    async function getUserInfo() {
        await axios
            .post(`${API_DEFAULT}userInfo`, { users_mail: userInfo.email }, { "Content-Type": "application/json" })
            .then((res) => {
                let tmp = res.data.data;
                if (tmp.status === 403) {
                    alert(
                        "Your account have not been approved."
                    );
                    window.location.replace(searchParams.get("redirect_uri") ? searchParams.get("redirect_uri") : ACCOUNT_INDEX);
                } else if (tmp.status === 401) {
                    alert(
                        "Please update user's profile first."
                    );
                    window.location.replace(ACCOUNT_INDEX);
                } else if (tmp.status === 500) {
                    alert("There's some problem appeared.");
                    console.error(tmp);
                } else if (tmp.users_name === "") {
                    tmp.users_name = `${userInfo.firstName} ${userInfo.lastName}`
                }

                setApplication((prev) => {
                    return { ...prev, ...tmp };
                });
            })
            .catch((e) => {
                console.error(e);
            });
    }
    // 取得dataset name後，透過後端取得資料集ID
    useEffect(() => {
        if (datasets_name !== undefined && userInfo.email !== undefined && sysadmin===false) {
            getDatasetsInfo();
        }
    }, [datasets_name, userInfo.email, sysadmin]);
    // 透過後端取得資料集資料
    const getDatasetsInfo = async () => {
        await axios
            .post(
                API_DATASETSINFO,
                { id: datasets_name },
                { "Content-Type": "application/json" }
            )
            .then((res) => {
                const tmp = res.data.data;
                setApplication((prev) => {
                    return {
                        ...prev,
                        datasets_id: tmp.id,
                        datasets_title: tmp.title,
                        datasets_maintainer_email: tmp.datasets_maintainer_email,
                        datasets_author_email: tmp.datasets_author_email,
                    };
                });

                if (tmp.datasets_maintainer_email === userInfo.email || tmp.datasets_author_email === userInfo.email) {
                    // 防止 審核者申請自己審核的資料、作者申請自己的資料
                    alert("You do not need to apply for this dataset!");
                    window.location.replace(searchParams.get("redirect_uri") ? searchParams.get("redirect_uri") : CKAN_HOST);
                } else if (tmp.datasets_maintainer_email === "") {
                    // 防止申請沒有審核者的資料集
                    alert(
                        "This dataset is currently unavailable to request, please contact the dataset administrator."
                    );
                    window.location.replace(searchParams.get("redirect_uri") ? searchParams.get("redirect_uri") : CKAN_HOST);
                }
            })
            .catch((e) => {
                alert("This dataset is currently unavailable to request, please contact the dataset administrator.");
                window.location.replace(searchParams.get("redirect_uri") ? searchParams.get("redirect_uri") : CKAN_HOST);
                console.error(e);
            });
    };
    // 簽名檔變動
    useEffect(() => {
        if (imageURL !== null) {
            document.body.style.overflow = 'hidden';
            document.body.style.cursor = 'wait';
            let input = document.querySelector('#signature');
            input.style.display = "block";
            const rect = input.getBoundingClientRect();
            const doc = new jsPDF("p", "mm", "a4");

            const pdfWidth = doc.internal.pageSize.getWidth();
            const imgWidth = rect.width + 10;
            const ratio = pdfWidth / imgWidth;

            doc.html(input, {
                callback: function (pdf) {
                    setBlob(pdf.output('blob'));
                },
                margin: [10, 5, 10, 5],
                html2canvas: {
                    scale: ratio,
                },
            });

            input = null;
        }
    }, [imageURL]);

    useEffect(() => {
        if (blob !== null && document.querySelector(`#signature`) !== undefined) {
            let input = document.querySelector(`#signature`);
            input.style.display = "none";
            document.body.style.overflow = 'auto';
            document.body.style.cursor = 'default';
        }

    }, [blob]);

    // 下載檔案
    useEffect(() => {
        if (agreeID === "unsigned-agreement" || agreeID === "signed-agreement") {
            document.body.style.overflow = 'hidden';
            document.body.style.cursor = 'wait';

            let imgarea = document.querySelector(`#signature-img`);
            if (document.querySelector(`#signature-img`) !== null) {
                imgarea.style.display = agreeID === "signed-agreement" ? "block" : "none";
            }

            let input = document.querySelector('#signature');
            input.style.display = "block";
            const rect = input.getBoundingClientRect();
            const doc = new jsPDF("p", "mm", "a4");

            const pdfWidth = doc.internal.pageSize.getWidth();
            const imgWidth = rect.width + 10;
            const ratio = pdfWidth / imgWidth;

            doc.html(input, {
                callback: function (pdf) {
                    pdf.save(agreeID === "signed-agreement" ? "Data Sharing Agreement(signed).pdf" : "Data Sharing Agreement.pdf");
                    setAgreeID("signature");
                },
                margin: [10, 5, 10, 5],
                html2canvas: {
                    scale: ratio,
                },
            });

        } else {
            let input = document.querySelector(`#signature`);
            input.style.display = "none";
            document.body.style.overflow = 'auto';
            document.body.style.cursor = 'default';
        }
    }, [agreeID]);

    // 查詢紀錄，查看是否申請過
    useEffect(() => {
        if (userInfo.email !== undefined && application.datasets_id !== undefined) {
            getApplicationRecord();
        }
    }, [application.datasets_id]);

    const getApplicationRecord = async () => {

        await axios
            .post(API_APPLICATION_RECORDS,
                {
                    users_mail: userInfo.email,
                    datasets_id: application.datasets_id,
                },
                { "Content-Type": "application/json" })
            .then((res) => {
                let tmp = res.data.data;
                if (Object.keys(tmp).length) {
                    // 0: 未審核, 1: 核准, 2: 待補件, 3: 失敗
                    if (tmp.currently !== 3) {
                        // 先前有申請過並且沒失敗
                        alert("You have already applied for this dataset.");
                        navigate("/");
                    } else {
                        setApplication((prev) => {
                            return { ...prev, ...tmp };
                        });
                    }
                } 
            })
            .catch((e) => {
                console.error(e);
            });
    }

    // 連線後端提交申請
    const connect = async (data) => {

        const results = await axios({
            method: 'post',
            url: API_APPLY,
            data: data,
            headers: { 'Content-Type': 'multipart/form-data' }
        });

        return results;
    };
    // 點選送出鈕後會處理的事情
    async function submitFun(e) {
        e.preventDefault();
        const form = e.target;

        const formData = new FormData(form);

        if (blob === null) {
            alert("Please sign on the signature pad and press the check mark to sign the document.");
        } else {
            formData.append('file', blob);

            formData.append('datasets_id', application.datasets_id);
            formData.append('datasets_title', application.datasets_title);
            formData.append('datasets_maintainer_email', application.datasets_maintainer_email);

            // 申請者資訊
            formData.append('applicant_mail', userInfo.email);
            formData.append('applicant_name', `${userInfo.firstName} ${userInfo.lastName}`);
            formData.append('applicant_firstname', userInfo.firstName);
            formData.append('applicant_lastname', userInfo.lastName);
            formData.append('applicant_unit', userInfo.users_unit);
            formData.append('applicant_jobtitle', userInfo.users_jobtitle);

            const res = await connect(formData);
            if (res.data.statuscode === 200) {
                alert(
                    `It has been sent successfully, your application number is ${res.data.data}.`
                );
                navigate("/");
            } else {
                alert(`${res.data.data}`);
            }
        }

    }


    return (
        <div className="Allbackground">
            <div className="hints-bar">
                <div className="hints-text">
                    Dataset Application
                </div>
                <div className="hints-btn">
                    <button type="button"
                        id="apply_reset"
                        className="applyBtn btn-outline-gray"
                        onClick={() => { document.querySelector('#form-reset').click(); }}
                    >
                        Reset
                    </button>
                    <button type="button"
                        id="appli_submit"
                        className="applyBtn btn-outline-success"
                        onClick={() => { document.querySelector('#form-submit').click(); }}
                    >
                        Submit
                    </button>
                    <button type="button"
                        id="apply_save"
                        className="applyBtn btn-borderless-light"
                        onClick={() => { document.querySelector('#form-close').click(); }}
                    >
                        x Close
                    </button>
                </div>
            </div>

            <div className="apply-card">
                <div className="apply-card-content">
                    <form onSubmit={submitFun} className="Applyform" >
                        {/* 內容 */}
                        <div className="paper">
                            <BasicInfoArea application={application} user_role={0} />

                            <ApplicationArea
                                application={application}
                                pagestate={1}
                                user_role={0}
                                imageURL={imageURL}
                                setImageURL={setImageURL}
                                agreeID={agreeID}
                                setAgreeID={setAgreeID }
                            />
                        </div>
                        {/* 按鈕 */}
                        <div className="ButtonGroup ">
                            <button
                                type="button"
                                className="Button Close"
                                id="form-close"
                                onClick={() => {
                                    navigate("/");
                                }}
                            >
                                Close
                            </button>

                            <button type="reset" className="Button Reset" id="form-reset">
                                Reset
                            </button>
                            <button type="submit" className="Button Submit" id="form-submit">
                                Submit
                            </button>
                        </div>
                    </form>
                </div>
            </div>
    
        </div>

    );
}
