// 總覽表格
// 套件
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { FaArrowRotateLeft } from "react-icons/fa6";
import { IoClose } from "react-icons/io5";
// 檔案
import "./index.css";
import Field from "./field";
import { API_APPLICATION, API_CHANGE } from "../../global/constants";

export default function OverviewTabel({ data, tablestate, identity }) {
    // 切換頁面
    const navigate = useNavigate();

    // 點選申請件後跳轉
    function moreDetail(applications_id) {
        // 跳轉
        navigate(`/application/${applications_id}?tab=${identity}`);
    }
    // 重新申請按鈕
    function reapply(element) {
        if (window.confirm("Your previous application was rejected. Please review the rejection reasons to avoid another rejection. Do you want to proceed with the reapplication?")) {
            navigate(`/apply/${element.datasets_name}?redirect_uri=/`);
        }
        
    }
    // 取消申請按鈕
    function withdraw(element) {
        // 取消申請 需重複確認
        if (window.confirm("Are you sure you want to abandon your application?")) {
            let reason = "";
            while (reason === "") {
                reason = window.prompt("Please provide the reason for cancelling your application:");
            }
            getApplication(reason, element.application_uuid);
        }
    }

    // 向後端取得申請資料
    const getApplication = async (reason, applications_id) => {
        await axios
            .post(
                API_APPLICATION,
                { applications_id: applications_id },
                { "Content-Type": "application/json" }
            )
            .then((res) => {
                let tmp = res.data.data;
                tmp.reason = reason;
                tmp.data_changed = false;
                tmp.userole = 1;
                tmp.currently = 3;
                // 申請者資訊
                tmp.applicant_name = tmp.users_name;
                tmp.applicant_email = tmp.applicant_mail;
                tmp.applicant_unit = tmp.users_unit;
                tmp.applicant_jobtitle = tmp.users_jobtitle;
                tmp.project_title = tmp.title;
                // 更改資料
                change(tmp);
                console.log(tmp);
            })
            .catch((e) => console.error(e));
    };

    // 送出更改請求
    const change = async (data) => {
        await axios
            .post(API_CHANGE, data, { "Content-Type": "application/json" })
            .then((res) => {
                alert(`Application ${data.applications_id} was changed successfully.`);
                window.location.href = `/`;
            })
            .catch((e) => console.error(e));
    };

    return data[0] !== undefined && data[tablestate.identity] !== null ? (
        <table className="overviewTable">
            <thead>
                <tr>
                    {Field[tablestate.identity].map((element) => {
                        return (
                            <td className="overviewThead" key={`th${element.column}`}
                                style={{ width: element.width }}                >
                                {element.title}
                            </td>
                        );
                    })}
                </tr>
            </thead>
            <tbody>
                {data[tablestate.identity].list.map((element, index) => {
                    // 判斷是否為要顯視的狀態
                    if (tablestate.state[Object.keys(tablestate.state)[element.status]] === false) return null;
                    return (
                        <tr
                            key={`tr${index}`}
                        >
                            {Field[tablestate.identity].map((field_element, field_index) => {
                                const idClass = field_index === 0 ? "idClass" : "";
                                const img = field_element.column === "currently"
                                    ? <div
                                        className="status_col"
                                        onClick={() => moreDetail(element.application_uuid)}
                                    >
                                        <img src={require(`../images/${element.icon}`)} className="status-icon" />
                                        {element[field_element.column] }
                                    </div>
                                    : field_element.column === "action"
                                        ? element.status === 1
                                            ? <></>
                                            : element.status === 3
                                                ? <FaArrowRotateLeft className="apply_btn" onClick={() => reapply(element)} />
                                                : <IoClose className="withdraw_btn" onClick={() => withdraw(element)} />

                                        : <div onClick={() => moreDetail(element.application_uuid)}>
                                            {element[field_element.column]}
                                        </div>;

                                return (
                                    <td
                                        className={`overviewTbody ${idClass}`}
                                        key={`td${index}_${field_index}`}
                                    >
                                        {img}
                                    </td>
                                );
                            })}
                        </tr>
                    );
                })}
            </tbody>
        </table>
    ) : (
        <></>
    );
}
