const BasicField = [
  //{
  //  id: 0,
  //  column: "applications_id",
  //  title: "Application ID",
  //  establish: 1,
  //},
  {
    id: 1,
    column: "datasets_title",
    title: "Dataset",
    establish: 0,
  },
  //{
  //  id: 2,
  //  column: "filingdate",
  //  title: "Filing date",
  //  establish: 1,
  //},
  //{
  //  id: 3,
  //  column: "update_time",
  //  title: "Update",
  //  establish: 1,
  //},
  {
    id: 3,
    column: "users_name",
    title: "Applicant",
    establish: 0,
  },
  {
    id: 4,
    column: "users_unit",
    title: "Affiliated units",
    establish: 0,
  },
  {
    id: 5,
    column: "users_jobtitle",
    title: "Job title",
    establish: 0,
  },
];
// id基本資料欄為編號
// column資料庫中資料表裡的欄位名稱
// title前端顯示的文字
// establish是否需要建立資料庫中的資料後產生
export default BasicField;
