// 套件
import axios from "axios";
import React from "react"
import { useKeycloak } from "@react-keycloak/web";
import { useEffect, useState } from "react";
import Logout from "@mui/icons-material/Logout";
import { Button, Menu, MenuItem } from "@mui/material";
// 檔案
import "../index.css";
import { ACCOUNT_INDEX, CKAN_HOST, API_DEFAULT } from "../../../global/constants";
//import { profileicon } from "../components/profileicon.png"
//import Pidialog from "../../piDialog";

export default function User() {
    // 設定keycloak
    const { keycloak } = useKeycloak();
    const [userInfo, setUserInfo] = useState();
    // 使用者選單開關
    function handleClick() {
        document.getElementById("user-dropdown").classList.toggle("show");
    };

    window.onclick = function (event) {
        if (!event.target.matches('.Usericon')) {
            var dropdowns = document.getElementsByClassName("dropdown-menu");
            var i;
            for (i = 0; i < dropdowns.length; i++) {
                var openDropdown = dropdowns[i];
                if (openDropdown.classList.contains('show')) {
                    openDropdown.classList.remove('show');
                }
            }
        }
    }
    
    async function getUserInfo() {
        const res = await keycloak.loadUserProfile();
        await axios
            .post(`${API_DEFAULT}userInfo`, { users_mail: res.email }, { "Content-Type": "application/json" })
            .then((res_user) => {
                let tmp = res_user.data.data;
                res.firstName = tmp.users_firstname;
                res.lastName = tmp.users_lastname;
                setUserInfo(res);
            })
            .catch((e) => {
                console.error(e);
            });
    }
    useEffect(() => {
        getUserInfo();
    }, [keycloak]);

    return userInfo !== undefined ? (
        <div className="UserContainer">
            {/* 頭像點選 */}
            <button
                className="UserButton"
                id="basic-button"
                onClick={() => {
                    handleClick();
                }}
            >
                <img src={require('../../images/profileicon.png')} className="Usericon" />
            </button>
            {/* 使用者選單 */}
            <div className="dropdown-menu" id="user-dropdown">
                <div className="dropdown-item-text d-flex align-items-center" id="user-info-group">
                    <img src={require('../../images/profileicon.png')} id="user-info-icon" />
                    <div>
                        <div id="user-fullname">{`${userInfo.firstName} ${userInfo.lastName}`}</div>
                        <div id="user-mail">{`${userInfo.email}`}</div>
                    </div>
                </div>
                <a className="dropdown-item" href={CKAN_HOST }>AI Datasharing</a>
                <a className="dropdown-item" href="/">My Application</a>
                <a className="dropdown-item" href={ACCOUNT_INDEX}>My Profile</a>
                <div className="dropdown-item-text">
                    <button className="Logout"
                        onClick={() => {
                            keycloak.logout();
                        }}
                    >
                        Log out
                    </button>
                </div>
            </div>
        </div>
    ) : (
        <></>
    );
}
