// 套件
import React, { useEffect } from "react";
import { useKeycloak } from "@react-keycloak/web";
// 檔案
import Layout from "./components/layout";
import Login from "./pages/login";
import Loading from "./components/loading";

function App() {
  const { keycloak, initialized } = useKeycloak();
  const isLoggedIn = keycloak.authenticated;

  useEffect(() => {
    if (keycloak && initialized) {
      keycloak.onTokenExpired = () => keycloak.updateToken(600);
    }
    return () => {
      if (keycloak) keycloak.onTokenExpired = () => {};
    };
  }, [initialized, keycloak]);

  return isLoggedIn ? (
    <Layout />
  ) : isLoggedIn === undefined ? (
    <Loading />
  ) : (
    <Login />
  );
}

export default App;
