const field = [
    // column=>DB內的欄位名稱
    // name=>管理者顯示的欄位名稱
    // title=>申請者顯示的題目內容
    // notic=>題目輸入限制
    //{
    //  id: 0,
    //  column: "users_jobtitle",
    //  title: "Jobtitle",
    //  notic: "",
    //  tag: "input",
    //  type: "text",
    //  minlength: 1,
    //  maxlength: 255,
    //},
    //{
    //  id: 1,
    //  column: "users_unit",
    //  title: "Affiliated Unit",
    //  notic: "",
    //  tag: "input",
    //  minlength: 1,
    //  maxlength: 255,
    //},
    {
        id: 2,
        column: "title",
        title: "Project Title",
        notic:
            "The majority of applications to this dataset are for data only. The purpose of the application is for NYCU Data Portal to determine whether the proposed research project is health-related,feasible and in the public interest. For this, we require a brief synopsis of the research plan (i.e. a description of the aims,methods and intended outputs) rather than a full scientific review.",
        tag: "textarea",
        minlength: 1,
        maxlength: 200,
        charcount: 200,
    },
    {
        id: 3,
        column: "summary",
        title: "Project Summary",
        notic:
            "Please provide a lay summary of your research project in plain English, stating the aims, scientific rationale, projectduration and public health impact.",
        tag: "textarea",
        minlength: 2000,
        maxlength: 5000,
        charcount: 5000,
        wordcount: 400,
    },
    {
        id: 4,
        column: "motivation",
        title: "Project Motivation",
        notic:
            "The background and scientific rationale of the proposed research project in general",
        tag: "textarea",
        minlength: 1000,
        maxlength: 5000,
        charcount: 5000,
        wordcount: 200,
    },
    {
        id: 5,
        column: "method",
        title: "Project Method",
        notic:
            "Please provide a lay summary of your research project in plain English, stating the aims, scientific rationale, projectduration and public health impact.",
        tag: "textarea",
        minlength: 1500,
        maxlength: 5000,
        charcount: 5000,
        wordcount: 300,
    },
    {
        id: 6,
        column: "sample",
        title: "Sample",
        notic:
            "As such, the first two questions we ask are whether your application involves access to samples or re-contact as this will require some additional information and as is set out inthe Access Procedures (our data are not depletable, but our samples and re-contact opportunities are depletable)recontact/sample applications are assessed to a different (more exacting) standard. Does your project require biological samples?",
        tag: "input",
        type: "radio",
        option: [
            { id: 0, value: true, text: "Yes" },
            { id: 1, value: false, text: "No" },
        ],
    },
    {
        id: 7,
        column: "recontact",
        title: "Recontact",
        notic: "Does your project need to recontact participants?",
        tag: "input",
        type: "radio",
        option: [
            { id: 0, value: true, text: "Yes" },
            { id: 1, value: false, text: "No" },
        ],
    },
    {
        id: 8,
        column: "target",
        title: "Research Target",
        tag: "textarea",
        minlength: 500,
        maxlength: 5000,
        charcount: 5000,
        wordcount: 100,
    },
    {
        id: 9,
        column: "expected",
        title: "Expected Results",
        notic:
            "The expected value of the research (taking into account the public interest requirement)",
        tag: "textarea",
        minlength: 500,
        maxlength: 5000,
        charcount: 5000,
        wordcount: 100,
    },
    {
        id: 10,
        column: "duration",
        title: "Estimated Duration",
        notic:
            " What is the estimated duration of your project, in months? If you consider (because for example the project is oneinvolving the generation of hypotheses) that it would be difficult to set a fixed end point, we are prepared to consider arolling 3-year period (during which annual updates are required):",
        tag: "input",
        type: "number",
    },
    {
        id: 11,
        column: "agreement",
        title: "Data Sharing Agreement",
        notic: "Please download this pdf and read it carefully. The process requires the user(s) using the data to sign the agreement. ",
        filename: "Data Sharing Agreement.pdf",
        tag: "file",
        type: "file",
    },
    {
        id: 12,
        column: "signature",
        title: "Please sign on the signature pad, the signature will be added into the Data Sharing Agreement document automatically.",
        tag: "input",
        type: "pad",
    },
    {
        id: 13,
        column: "agreement",
        title: "Data Sharing Agreement",
        notic1: "Please click to read this file",
        notic2: "The process requires the data user(s) to sign the agreement. Then, upload the signed form to the platform. ",
        filename: "Data Sharing Agreement(signed).pdf",
        tag: "file",
        type: "file",
    },
];
export default field;
// 題目(name申請者/title審核者)
// 題目輸入限制(申請者)
// 退件備註(text申請者/text area審核者)
// 申請填寫內容(text area申請者/text審核者)
