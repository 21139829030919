// 基本資料
import React from "react";
import BasicField from "./basicField";
import "./index.css";
// 欠缺:keycloak資料取質
export default function BasicInfoArea({ application, user_role }) {

    //console.log(application);

  return (
    <>
      {BasicField.map((element) => {
        if (application[element.column] === undefined||application[element.column] === "") {
          // 若欄位沒有資料直接不顯示
          return null;
        } else if (
          //application.applications_id === undefined &&
          (element.column === "users_unit" ||
            element.column === "users_jobtitle")
        ) {
            return (
                <div className="institueColumn" key={element.column}>
                    <div
                        className={user_role === 2 ? "leftColumnTitle secondTitle text-gray" : "leftColumnTitle secondTitle"}
                    >
                        {element.title}
                    </div>
                    <div className="rightColumnInput">
                        {application[element.column]}
                    </div>
                </div>
            );
        } else {
          return (
            <div className="infoColumn" key={element.column}>
                  <div
                      className={user_role === 2 ? "leftColumnTitle secondTitle text-gray" : "leftColumnTitle secondTitle"}
                  >
                      {element.title}
                  </div>
              <div className="rightColumnInput">
                {application[element.column]}
              </div>
            </div>
          );
        }
      })}
    </>
  );
}
