// �@�ɫ���
// �M��
import React, { useEffect } from "react";
//�ɮ�
import "./agreement.css"

export default function Agreement({imgURL }) {


    return (
        <div
            className="sign-container"
            id="signature"
        >
            <h1>Data Sharing Agreement</h1>
            <p>Party A ____ (data sharing platform) ______</p>
            <p>Party B's _____ (the name of the juridical person or research team of the applicant)_____(Legal representative) _____</p>

            <h2>Article 1 (Purpose and Source of Contract)</h2>
            <ol>
                <li>This agreement aims to promote the transformation of healthcare catalyzed by digital technology, build a trusted and open data platform and sharing mechanism for medical artificial intelligence models, and protect the rights and interests of research subjects in human research.</li>
                <li>Party A provides ______ materials (hereinafter referred to as the "Materials at Issue") to Party B.</li>
                <li>Party B applies to Party A for ______ to share the information at issue, application number: ____.</li>
            </ol>

            <h2>Article 2 (Content Provided by the Platform)</h2>
            <ol>
                <li>The Materials at Issue are provided on an "as is" basis, and Party A does not guarantee their quality or suitability for a particular purpose, nor does it ensure that the use of the Materials at Issue will not infringe the rights of any third party.</li>
                <li>The anonymized materials are available for public download on this platform. However, the clinical data that are not anonymized must be applied to this platform. Contact the original data collector on the Platform. After obtaining the dynamic consent of the data subject, the above non-anonymized clinical data can be obtained.</li>
            </ol>

            <h2>Article 3 (Obligations of the Applicant)</h2>
            <ol>
                <li>The materials at issue shall be used only within the scope of academic research purposes. Party B shall not use them beyond the scope of the aforesaid purposes (including but not limited to insurance, marketing, profit-making, commercial purposes, etc.). If Party B intends to expand or change the purpose of the application as outlined in Article 1 of this Agreement, Party B shall notify Party A in writing in advance, and Party A shall review and approve the application before doing so.</li>
                <li>Party B shall set up a personal data protection management implementation organization. The management shall appoint a person in charge of personal information management to coordinate and implement compliance matters related to the Personal Data Protection Act of Taiwan, establish a personal information management organization, and carry out the planning, implementation, operation, supervision, inspection, maintenance, and improvement of the personal information management system, and carry out management review of the personal information management system regularly or in the event of significant changes to ensure the appropriateness and effectiveness of its operation. Please refer to Paragraph 5 of this Article for the obligations and scope of management of the organization responsible for protecting and managing personal information.</li>
                <li>Paragraph 2 of Article 2: The information disclosed by the Platform shall be downloaded only after Party B (including the agreement signed by Party B and a third party in line with the intent of this contract) to facilitate Party A to track the downloaded unit.</li>
                <li>Party B shall not re-identify or indirectly identify the data subjects at issue in any way. If the information is provided to a third party with the consent of the client, it is necessary to sign a agreement with the third party to require the third party not to re-identify or indirectly identify the third party and to stipulate penalties for liquidated damages and other items in Article 7 of this agreement for breach of contract.</li>
                <li>After Party B obtains the Materials at Issue, Party B shall keep and manage the Materials at Issue at the same level as the administrative, managerial, and technical aspects set forth by Party A. The personal data protection management organization will evaluate and check the relevant personal data management procedures, systems, and indicators to ensure the relevant procedures' effectiveness and whether they are implemented.</li>
                <li>If Party B finds any information security concerns or other improper or illegal circumstances, Party B shall notify Party A within 72 hours from the time of discovery (Party A's contact window: ___, telephone ____, e-mail _____). DS SolidWorks shall not delay notification on the grounds that the information is incomplete or that an investigation is ongoing.</li>
                <li>Party B shall not transfer, transmit, disclose, or otherwise hand over the Materials at Issue to a third party, except where Party B notifies Party A in writing in advance and obtains Party A's consent or Party B is required by law to transfer, transmit or disclose the Materials at Issue.</li>
                <li>If Party B delivers the materials at issue to a third party by the proviso of the preceding paragraph, Party B shall enter into a agreement with the third party that conforms to the intent of this Agreement and shall guarantee that the third party shall comply with its obligations under Paragraphs 1 to 5 of Article 3 of this Contract. If the third party breaches the contract, Party B shall be deemed to have breached the agreement and shall be dealt with by Article 7 of this Agreement.</li>
            </ol>

            <h2>Article 4 (Period of Data Retention and Use)</h2>
            <ol>
                <li>Party B may use the materials at issue within __ years from signing the contract. However, if the data subject chooses to withdraw from the program and ceases to provide the data, Party B shall cease to use the specific area of data from the day following the notice from Party A.</li>
                <li>Within one month after the expiration of the period outlined in the preceding paragraph, Party B shall submit evidence that it has destroyed the original data and copies of the materials at issue (including but not limited to paper documents, electronic signals, etc.). The same applies in the event of early termination or rescission of this Agreement.</li>
            </ol>

            <h2>Article 5 (Ownership of Intellectual Property Rights and Obligation to Quote)</h2>
            <ol>
                <li>Party B shall not claim intellectual property rights over the original data of the materials.</li>
                <li>For independent works (including but not limited to papers, journals, books, etc.) that Party B uses the original data of the materials at issue to conduct research, the copyright of the crops shall be enjoyed by Party B. The patent rights of Party B's invention using the data at issue for research and development shall also belong to Party B. However, Party B is obliged to indicate clearly the source of the original data and any works (including but not limited to papers, journals, books, etc.) published from Party A's database in the acknowledgments, abstracts or quotations of the published works or patent applications.</li>
                <li>Party B shall notify Party A in writing of the content and form of the published work within __ months from the day following the publication of the work in the preceding paragraph and the approval of the patent application. Party A may publish the information of Party B's research results on the website of the sharing platform.</li>
            </ol>

            <h2>Article 6 (Confidentiality Obligations)</h2>
            <p>Party B shall manage and preserve the data at issue with the duty of care of a good administrator by the information security standards set by Party A, such as listing and managing the information, locking or setting access permissions, and regularly reviewing the information security maintenance system.</p>

            <h2>Article 7 (Penalties)</h2>
            <ol>
                <li>If Party B uses the materials at issue beyond the purpose of the contract, delivers or discloses the materials at issue to a third party beyond the purpose of the contract, Party B shall unconditionally pay Party A punitive liquidated damages of NT$____ or NT$____ for each information, and Party AThis Agreement may be terminated or rescinded without notice.</li>
                <li>If Party B does not clearly indicate that the source of the information belongs to Party A in its crop or patent application, Party A has the right to request Party B to indicate within ___ days. If Party B fails to indicate on ___ day, Party B shall unconditionally pay Party A punitive liquidated damages of NT$____ / Punitive liquidated damages of NT$____ per information; This Agreement may be terminated or rescinded without notice.</li>
                <li>If Party B's representative, manager, employee, or assignee violates this contract, it shall be deemed that Party B has violated this contract, and Party B shall not take such action is exempted because a considerable duty of supervision has been met.</li>
                <li>If Party B causes damage to a third party, Party B shall be responsible and has nothing to do with Party A.</li>
                <li>Party B shall be liable for compensation if Party B causes damage to Party A (including but not limited to litigation costs, attorney fees, accountant fees, business losses, loss of goodwill, etc.) and if a third party asserts Party A or has a lawsuit, Party B shall assist Party A in handling the dispute with the duty of care of a good administrator.</li>
            </ol>

            <h2>Article 8 (Dispute Resolution)</h2>
            <p>In the event of any dispute, this Agreement shall be governed by the laws of the Republic of China, and the Taipei District Court of Taiwan shall be the court of first instance, excluding the jurisdiction of other courts.
                (blank below)</p>

            <h2>Two originals of this contract, one for each party.</h2>

            <h3>Party A:</h3>
            <h3>Representatives:</h3>
            <h3>Uniform Number:</h3>
            <h3>Address:</h3>

            <div className="partyB-info-group" id="partyB-info-group">
                <div className="partyB-info">
                    <h3>Party B:</h3>
                    <h3>Representatives:</h3>
                    <h3>Uniform Number:</h3>
                    <h3>Address:</h3>
                </div>

                {imgURL ? (
                    <div className="imgArea" id="signature-img">
                        <img
                            src={imgURL}
                            alt="signature"
                        />
                    </div>
                ) : null}
            </div>

            <p className="signdate">Month_______, Day_______, Year________, Republic of China</p>

        </div>
    );
}



