// 可視化圖表區
// 套件
import React from "react";
// 檔案
import "./index.css";

export default function Visualization({ stateNum, tablestate, setTablestate }) {

    function setShowState(arr) {
        setTablestate((prev) => {
            return {
                ...prev,
                state: {
                    "pending review.": arr[0],
                    "success.": arr[1],
                    "To be supplemented.": arr[2],
                    "fail.": arr[3],
                },
            };
        });
    }

    return (
        <div className="status_list" >
            {/*  //access:成功件數 //insufficient:待補件件數
        //application:申請中件數 */}

            {/*申請件總數量*/}
            <div className={`status_card status-secondary ${Object.values(tablestate.state).every((x) => x)
                    ? "active"
                    : ""
                }`}
                onClick={() => {
                    setShowState([true, true, true, true]);
                }}
            >
                <a className="status_text">Total</a>
                <a className="status_count">{stateNum.allNum}</a>
            </div>
            {/*待補件件數*/}
            <div className={`status_card status-warning ${(tablestate.state["To be supplemented."] === true && !Object.values(tablestate.state).every((x) => x))
                ? "active"
                : ""
                }`}
                onClick={() => {
                    setShowState([false, false, true, false]);
                }}
            >
                <a className="status_text">Sent Back</a>
                <a className="status_count">{stateNum.additional}</a>
            </div>
            {/*成功件數*/}
            <div className={`status_card status-success ${tablestate.state["success."] === true && !Object.values(tablestate.state).every((x) => x)
                ? "active"
                : ""
                }`}
                onClick={() => {
                    setShowState([false, true, false, false]);
                }}
            >
                <a className="status_text">Approved</a>
                <a className="status_count">{stateNum.success}</a>
            </div>
            {/*申請中件數*/}
            <div className={`status_card status-secondary ${tablestate.state["pending review."] === true && !Object.values(tablestate.state).every((x) => x)
                ? "active"
                : ""
                }`}
                onClick={() => {
                    setShowState([true, false, false, false]);
                }}
            >
                <a className="status_text">Pending</a>
                <a className="status_count">{stateNum.unreviewed}</a>
            </div>
            {/*失敗件數*/}
            <div className={`status_card status-danger ${tablestate.state["fail."] === true && !Object.values(tablestate.state).every((x) => x)
                ? "active"
                : ""
                }`}
                onClick={() => {
                    setShowState([false, false, false, true]);
                }}
            >
                <a className="status_text">Rejected</a>
                <a className="status_count">{stateNum.fail}</a>
            </div>

        </div>
    );
}
