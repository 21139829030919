// 總覽
// 套件
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useKeycloak } from "@react-keycloak/web";
import { useNavigate, useSearchParams } from "react-router-dom";
import "react-circular-progressbar/dist/styles.css";
// 檔案
import "./index.css";
import { API_OVERVIEW, ACCOUNT_INDEX, API_DEFAULT } from "../../global/constants";
import OverviewTabel from "../../components/overviewTable";
import Visualization from "../../components/visualization";
import Loading from "../../components/loading";
import PaperTab from "./components/paperTab";
// 待補審核者連線還有系統管理者連線
export default function Overview() {
    // keycloak
    const { keycloak } = useKeycloak();
    // 介面跳轉套件
    const navigate = useNavigate();
    // 取得上一頁網址
    const [searchParams, setSearchParams] = useSearchParams();
    // 取得用戶資料
    const [userInfo, setUserInfo] = useState({});
    useEffect(() => {
        getKeycloakUserProfile();
    }, [keycloak]);
    async function getKeycloakUserProfile() {
        // loadUserProfile才有attributes屬性
        const userProfile = await keycloak.loadUserProfile();
        setUserInfo(userProfile);
    }
    // 使用者資料變動後取得平台資料庫的使用者資料
    //useEffect(() => {
    //    if (userInfo.email !== undefined) {
    //        getUserInfo();
    //    }
    //}, [userInfo]);
    // 取得平台資料庫的使用者資料 確認是否已審核通過
    async function getUserInfo() {
        await axios
            .post(`${API_DEFAULT}userInfo`, { users_mail: userInfo.email }, { "Content-Type": "application/json" })
            .then((res) => {
                let tmp = res.data.data;
                if (tmp.status == 403) {
                    alert(
                        "Your account have not been approved."
                    );
                    window.location.replace(searchParams.get("redirect_uri") ? searchParams.get("redirect_uri") : ACCOUNT_INDEX);
                } else if (tmp.status == 401) {
                    alert(
                        "Please update user's profile first."
                    );
                    window.location.replace(ACCOUNT_INDEX);
                } else {
                    connect();
                }
            })
            .catch((e) => {
                console.error(e);
            });
    }
    // table要渲染的資料
    const [data, setData] = useState([]);
    // table條件
    const [tablestate, setTablestate] = useState({
        //0申請者 1審核者 2系統
        identity: searchParams.get("tab") ? parseInt(searchParams.get("tab")) : 0,
        state: {
            "pending review.": true,
            "success.": true,
            "To be supplemented.": true,
            "fail.": true,
        }, //同申請狀態 null代表全顯 格式為list
    });
    // 可視化資料
    const [stateNum, setStateNum] = useState({});

    // ----------以上為變數定義----------

    // 頁面開啟時，向後端取得資料
    useEffect(() => {
        if (userInfo.email !== undefined) {
            getUserInfo();
        }
    }, [userInfo.email]);
    // 連線後端取得資料
    const connect = async () => {
        await axios
            .post(
                API_OVERVIEW,
                { users_mail: userInfo.email },
                { "Content-Type": "application/json" }
            )
            .then((res) => {
                if (res.data.statuscode == 404) {
                    alert(res.data.data.message);
                    window.location.replace(ACCOUNT_INDEX);
                }
                setData(res.data.data);
            })
            .catch((e) => console.error(e));
    };
    // 設定tableState & stateNum
    useEffect(() => {
        if (data[0] !== undefined) {
            // data 還沒取值前不更改
            if (searchParams.get("tab") && data[parseInt(searchParams.get("tab"))] !== null) {
                // 回到上一次的狀態
                setTablestate((prev) => {
                    return { ...prev, identity: parseInt(searchParams.get("tab")) };
                });
                setStateNum(data[parseInt(searchParams.get("tab"))].state_num);
                setStateNum((prev) => {
                    return { ...prev, allNum: data[parseInt(searchParams.get("tab"))].all_num };
                });
            } else if (data[0] !== null) {
                // 使用者為Applicant, identity為0
                setTablestate((prev) => {
                    return { ...prev, identity: 0 };
                });
                setStateNum(data[0].state_num);
                setStateNum((prev) => {
                    return { ...prev, allNum: data[0].all_num };
                });
            } else if (data[1] !== null) {
                // 使用者為Inspector, identity為1
                setTablestate((prev) => {
                    return { ...prev, identity: 1 };
                });
                setStateNum(data[1].state_num);
                setStateNum((prev) => {
                    return { ...prev, allNum: data[1].all_num };
                });
            } else if (data[2] !== null) {
                // 使用者為Applicant, identity為2
                setTablestate((prev) => {
                    return { ...prev, identity: 2 };
                });
                setStateNum(data[2].state_num);
                setStateNum((prev) => {
                    return { ...prev, allNum: data[2].all_num };
                });
            }
        }
    }, [data]);
    // 再次設定 stateNum
    useEffect(() => {
        if (data[0] !== undefined) {
            // data 還沒取值前不更改
            setStateNum(data[tablestate.identity].state_num);
            setStateNum((prev) => {
                return {
                    ...prev,
                    allNum: data[tablestate.identity].all_num,
                };
            });
        }
    }, [tablestate]);
    // 當身分按鈕點擊後切換身分
    function changeTablestate(identity) {

        setSearchParams({ ["tab"]: identity });

        setTablestate((prev) => {
            return {
                ...prev,
                state: { 0: true, 1: true, 2: true, 3: true },
                identity: identity,
                // identity: tablestate.identity ? 0 : 1,
            };
        });
    }

    if (data.length === 0) {
        return <Loading />;
    }


    return data !== undefined &&
        (data[0] !== null || data[1] !== null || data[2] !== null) ? (
        <>
            <div className="Allbackground overview">
                <div className="overview-tab">
                    {/* 身分切換 */}
                    <ul className="overview-nav">
                        <li
                            className="overview-nav-item"
                            hidden={data[2] === null ? true : false}
                        >
                            <button
                                className={tablestate.identity === 2 ? "nowButton" : "changeButton"}
                                onClick={() => {
                                    changeTablestate(2);
                                }}
                            >
                                Sysadmin
                            </button>
                        </li>
                        <li
                            className="overview-nav-item"
                            hidden={data[1] === null ? true : false}
                        >
                            <button
                                className={tablestate.identity === 1 ? "nowButton" : "changeButton"}
                                onClick={() => {
                                    changeTablestate(1);
                                }}
                            >
                                Inspector
                            </button>
                        </li>
                        <li
                            className="overview-nav-item"
                            hidden={data[0] === null ? true : false}
                        >
                            <button
                                className={tablestate.identity === 0 ? "nowButton" : "changeButton"}
                                onClick={() => {
                                    changeTablestate(0);
                                }}
                            >
                                Applicant
                            </button>
                        </li>
                    </ul>

                    {/* 包覆所有頁面物件的容器 */}
                    <div className="overview-tab-content">
                        {/* 可視化圖表 */}
                        {tablestate.identity === 0 ? (
                            <Visualization
                                stateNum={stateNum}
                                tablestate={tablestate}
                                setTablestate={setTablestate}
                            />
                        ) : null}
                        {/* 白底部分 */}
                        <div className="overview-tab-pane">
                            {/* 審核者tab */}
                            <ul
                                className="StateTabContainer"
                                style={{ display: tablestate.identity === 1 ? "" : "none" }}
                            >
                                <PaperTab
                                    stateNum={stateNum}
                                    tablestate={tablestate}
                                    setTablestate={setTablestate}
                                />
                            </ul>
                            {/* 純表格 */}
                            <OverviewTabel
                                data={data}
                                tablestate={tablestate}
                                users_mail={userInfo.email}
                                identity={tablestate.identity}
                            />
                        </div>
                    </div>

                </div>
            </div>
        </>
    ) : (
        <>
            <div className="noData">
                Oops!
                <br /> Currently there is no data in the system.
            </div>
        </>
    );
}
