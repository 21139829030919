// 進度表
// 套件
import React, { useEffect, useState } from "react";
import { Chrono } from "react-chrono";
import { Paper } from "@mui/material";
import axios from "axios";
// 檔案
import { API_SCHEDULE } from "../../global/constants";
import "./index.css";

export default function Schedule({ applications_id }) {
  // 進度條需要的資料
  const [timeLineData, setTimeLineData] = useState([]);
  // 所有後端取回的資料
  const [data, setData] = useState({});
  //
  // 連線取得所有進度資料
  async function connect() {
      //console.log("Connect");
    // const connect = async () => {
    await axios
      .post(
        API_SCHEDULE,
        { applications_id: applications_id },
        { "Content-Type": "application/json" }
      )
      .then(async (res) => {
        setTimeLineData(res.data.data.item);
        setData(res.data.data);
      })
      .catch((e) => console.error(e));
  }
  // 開啟頁面時向後端取資料
  useEffect(() => {
    connect();
  }, []);
  function timeLine() {
    // 避免沒有資料的情況下顯示
    if (timeLineData < 1) {
      return null;
    }
    return (
      <Chrono
        items={timeLineData}
        mode="VERTICAL"
        activeItemIndex={timeLineData.length - 1} // focuce在最新狀態上
        allowDynamicUpdate={true} // 允許動態更新
        hideControls="true" //隱藏按鈕
        disableClickOnCircle={true} //關閉圓圈點選動作
        // onItemSelected // 選擇後的動作
        theme={{
          primary: "#a3c5f9",
          secondary: "#f0f7fd",
          cardBgColor: "#f9fcff",
          titleColor: "#526888",
          cardTitleColor: "#124086",
          titleColorActive: "#124b7c",
        }}
        fontSizes={{
          title: "1rem",
          cardTitle: "1.1rem",
          cardSubtitle: "1rem",
          TimelineContentDetails: "1rem",
        }}
        slideShow
        slideItemDuration={6500}
        slideShowType="reveal"
        // cardHeight={{ height: "1.2rem" }}
      />
    );
  }

  return (
    <>
      {/* 最後一筆class="Shape-sc-18iuiou-3 ezOQyU active" */}
      <Paper>
        <div
          className="scheduleHead"
          style={{
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div className="datasetTitle">{`>> Data Set: ${data.datasets_title}`}</div>
          <div className="datasetApplication">{`Application Number: ${applications_id}`}</div>
        </div>
        <div className="Timelinecontainer">{timeLine()}</div>
      </Paper>
    </>
  );
}
