// 套件
import React from "react";
// 檔案
import User from "./components/User";
import "./index.css";

export default function Nav() {
  return (
    <>
      <nav className="nav">
        <div
          className="navLogo"
          onClick={() => {
            window.location.href = "/";
          }}
        >
          Dataset Application Portal
        </div>
        <User />
      </nav>
    </>
  );
}
