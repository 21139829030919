const API_HOST = process.env.REACT_APP_API_HOST;
export const API_DEFAULT = `${API_HOST}/`;
export const API_DATASETSINFO = `${API_HOST}/ckan_datasetInfo`;
export const API_APPLY = `${API_HOST}/apply`;
export const API_APPLICATION = `${API_HOST}/application`;
export const API_COMMENT = `${API_HOST}/comment`;
export const API_CHANGE = `${API_HOST}/change`;
export const API_SCHEDULE = `${API_HOST}/schedule`;
export const API_OVERVIEW = `${API_HOST}/overview`;
export const API_PERMISSIONS = `${API_HOST}/permissions`;
export const API_SYSADMIN = `${API_HOST}/sysAdmins`;
export const API_AGREEMENT_DOWNLOAD = `${API_HOST}/downloadAgreement`;
export const API_APPLICATION_RECORDS = `${API_HOST}/application/records`;
export const API_APPLICATION_REVISE = `${API_HOST}/application/revise`;

// keycloak
export const KEYCLOAK = process.env.REACT_APP_KEYCLOAK;
export const KC_REALM = "datasharing";
export const KC_CLIENT = "client1";
export const KC_CHANGE_USER_ATTRIBUTES = `${KEYCLOAK}/admin/realms/${KC_REALM}/users`;

// Account management system
const ACCOUNT_HOST = process.env.REACT_APP_ACCOUNT_HOST;
export const ACCOUNT_INDEX = `${ACCOUNT_HOST}/`

// Data Sharing Ckan
export const CKAN_HOST = process.env.REACT_APP_CKAN_HOST;
