import React from "react";
import { useNavigate } from "react-router-dom";
import { PiCaretCircleRightThin } from "react-icons/pi";

function DetailButtons({ applications_id }) {
  const navigate = useNavigate();

  return (
    <div className="MoreContainter">
      <button
        className="More"
        onClick={() => {
          navigate("/");
        }}
      >
        {/* <PiCaretCircleRightThin className="BackIcon" /> */}
        {`<< back `}
      </button>
      <button
        className="More"
        onClick={() => navigate(`/application/${applications_id}`)}
      >
        {` form >>`}
        {/* <PiCaretCircleRightThin className="MoreIcon" /> */}
      </button>
    </div>
  );
}

export default DetailButtons;
