const Field = {
  0: [
    {
      id: 0,
      column: "applications_id",
      title: "ID",
      width: '5%',
    },
    {
      id: 1,
      column: "datasets_title",
      title: "Dataset",
      width: '30%',
    },
    {
      id: 2,
      column: "fillingdate",
      title: "Filling Date",
      width: '17%',
    },
    {
      id: 3,
      column: "update_time",
      title: "Update Time",
      width: '17%',
    },
    {
      id: 4,
      column: "currently",
      title: "Status",
      width: '16%',
    },
    {
      id: 5,
      column: "action",
      title: "action",
      width: '5%',
    },
  ],
  1: [
      {
          id: 0,
          column: "applications_id",
          title: "ID",
          width: '5%',
      },
      {
          id: 1,
          column: "datasets_title",
          title: "Dataset",
          width: '25%',
      },
      {
          id: 2,
          column: "users_mail",
          title: "Email",
          width: '25%',
      },
      {
          id: 3,
          column: "fillingdate",
          title: "Filling Date",
          width: '15%',
      },
      {
          id: 4,
          column: "update_time",
          title: "Update Time",
          width: '15%',
      },
      {
          id: 5,
          column: "currently",
          title: "Status",
          width: '15%',
      },
  ],
  2: [
      {
          id: 0,
          column: "applications_id",
          title: "ID",
          width: '5%',
      },
      {
          id: 1,
          column: "datasets_title",
          title: "Dataset",
          width: '25%',
      },
      {
          id: 2,
          column: "users_mail",
          title: "Email",
          width: '25%',
      },
      {
          id: 3,
          column: "fillingdate",
          title: "Filling Date",
          width: '15%',
      },
      {
          id: 4,
          column: "update_time",
          title: "Update Time",
          width: '15%',
      },
      {
          id: 5,
          column: "currently",
          title: "Status",
          width: '15%',
      },
  ],
};
export default Field;
