import React from "react";
import { RouterProvider, createBrowserRouter } from "react-router-dom";

import Nav from "../nav";
import ErrorPage from "../errorPage";
import Overview from "../../pages/overview";
import Detail from "../../pages/detail";
import Apply from "../../pages/apply";
import Application from "../../pages/application";
import SignPDF from "../signPDF"
import "./index.css";
function Layout() {
    // 路由
    const router = createBrowserRouter([
        {
            //總覽
            path: "/",
            element: <Overview />,
            errorElement: <ErrorPage />,
        },
        {
            //目前進度
            path: "/detail/:applications_id",
            element: <Detail />,
            errorElement: <ErrorPage />,
        },
        {
            //首次申請
            path: "apply/:datasets_name",
            element: <Apply />,
            errorElement: <ErrorPage />,
        },
        {
            //已建立的申請
            path: "application/:applications_id",
            element: <Application />,
            errorElement: <ErrorPage />,
        },
        {
            //共享契約
            path: "/agreement",
            element: <SignPDF />,
            errorElement: <ErrorPage />,
        },
    ]);

    return (
        <>
            {
                window.location.pathname === "/agreement"
                    ? (
                        <RouterProvider router={router} />
                    )
                    : (
                        <>
                            <Nav />
                            <div className="spaceDiv" />
                            <RouterProvider router={router} />
                            <div id="footer" className="border-top footer text-muted">
                                &ensp;&copy; {new Date().getFullYear() } - AI Data Sharing
                            </div>
                        </>
                    )
            } 
        </>
    );
}

export default Layout;
