// ��g�O
// �M��
import React, { useState, useRef, useEffect } from "react";
import SignaturePad from "react-signature-canvas";
import { BsCheck } from "react-icons/bs";
import { FaArrowRotateLeft } from "react-icons/fa6";
// download html as pdf
import jsPDF from 'jspdf'
// �ɮ�
import "./index.css";
import Agreement from "../signPDF/agreement";

export default function Canvas({ id, name, state, setState, imageURL, setImageURL, agreeID, setAgreeID }) {

    const sigCanvas = useRef({});
    //const [agreeID, setAgreeID] = useState(id);

    const clear = () => {
        sigCanvas.current.clear();
        setImageURL(null);
    }

    const save = () => {
        if (!sigCanvas.current.isEmpty()){
            let imageData = sigCanvas.current.getTrimmedCanvas().toDataURL("image/png");
            setState(0);
            setImageURL(imageData);
        }
    };

    //window.onresize = resizeFinish;

    //function resizeCanvas() {
    //    let canvas = document.getElementById('canvas');
    //    let ctx = canvas.getContext('2d');

    //    let tmpDataURL = sigCanvas.current.getTrimmedCanvas().toDataURL("image/png");
    //    let tmpImg = document.createElement('img');
    //    tmpImg.src = tmpDataURL;

    //    console.log(tmpImg);

    //    tmpImg.onload = function () {
    //        ctx.drawImage(tmpImg, canvas.getBoundingClientRect().width, canvas.getBoundingClientRect().height);
    //    }
    //}

    //function resizeFinish() {
    //    clearTimeout(window.resizedFinished);
    //    window.resizedFinished = setTimeout(function () {
    //        resizeCanvas();
    //    }, 500);
    //}

    useEffect(() => {
        if (state === 1) {
            setAgreeID("unsigned-agreement");
            setState(0);
        } else if (state === 2) {
            setAgreeID("signed-agreement");
            setState(0);
        }
        //else if (state === 0 && document.querySelector(`#signature`) !== undefined) {
        //    const input = document.querySelector(`#signature`);
        //    input.style.display = "none";
        //    input.setAttribute("id", "signature");
        //    setAgreeID("signature");
        //    document.body.style.overflow = 'auto';
        //    document.body.style.cursor = 'default';
        //}

    }, [state]);

    function downloadAgreement() {
        setState(2);
    }

    return (
        <>
            <div className="Agreement-sign-group mb-2" id="agreement-sign-group">
                <div className="signArea">
                    <SignaturePad
                        ref={sigCanvas}
                        canvasProps={{
                            className: "signatureCanvas",
                            id: "canvas"
                        }}
                    />
                    <BsCheck className="canvas_btn_save" onClick={save} />
                    <FaArrowRotateLeft className="canvas_btn_clear" onClick={clear} />
                    {/*<input
                        id={id}
                        name={name}
                        className="sign-input"
                        defaultValue={imageURL ? imageURL : null}
                        required
                    />
                    */}
                </div>
                {/*
                    {imageURL ? (
                    <img
                        src={imageURL}
                        alt="my signature"
                        style={{
                            display: "block",
                            margin: "0 auto",
                            border: "1px solid black",
                            width: "fit-content",
                            height: "fit-content",
                            padding: "2rem",
                        }}
                    />

                ) : null}
                */}
                {
                    imageURL ? (
                        <div className="signed-agreement-group">
                            <div className="sigend-agreement-info">
                                <a className="sigend-agree-file">Data Sharing Agreement(signed).pdf</a>
                                <a>5.3MB</a>
                            </div>
                            <div className="agreement-button-group">
                                <a className="deleteBtn" onClick={clear}>Delete</a>
                                <a className="downloadBtn" onClick={downloadAgreement}>Download</a>
                            </div>
                        </div>
                    ): null
                }
            </div>

            <div className="download-area">
                <Agreement
                    imgURL={imageURL}
                />
            </div>    
            
        </>
    );
}