// 申請者查看進度&檢視文件 
// 已廢除
// 套件
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { useKeycloak } from "@react-keycloak/web";
// 檔案
import { API_PERMISSIONS, API_SYSADMIN } from "../../global/constants";
import Schedule from "../../components/schedule";
import DetailButtons from "../../components/detailButtons";
import "./index.css";

export default function Detail() {
  // 切換頁面
  const navigate = useNavigate();
  // keycloak
  const { keycloak } = useKeycloak();
  // 取得用戶資料
  const [userInfo, setUserInfo] = useState();
  useEffect(() => {
    getKeycloakUserProfile();
  }, [keycloak]);
  async function getKeycloakUserProfile() {
    // loadUserProfile才有attributes屬性
    const userProfile = await keycloak.loadUserProfile();
    setUserInfo(userProfile);
  }
  // 從網址抓取顯示id
  const { applications_id } = useParams();
  // 向後端取得可以觀看的使用者
  async function getPremmison() {

      const res_admin = await axios.get(API_SYSADMIN);
      if (res_admin.data.statuscode === 500) {
          alert(
              "There seems to be some problem with this application. Please notify the administrator."
          );
          navigate("/");
      } else {
          const sysadmins = res_admin.data.data;
          if (!sysadmins.includes(userInfo.email)) {
              const res = await axios.post(API_PERMISSIONS, {
                  applications_id: applications_id,
              });
              if (res.data.statuscode === 500) {
                  alert(
                      "There seems to be some problem with this application. Please notify the administrator."
                  );
                  navigate("/");
              } else {
                  const users_id = res.data.data.users_id;
                  const datasets_maintainer_email = res.data.data.datasets_maintainer_email;
                  if (
                      userInfo.id !== users_id &&
                      userInfo.email !== datasets_maintainer_email
                  ) {
                      navigate("/");
                      alert("You do not have access rights.");
                  }
              }
          }
      }

  }
  useEffect(() => {
    if (userInfo !== undefined) {
      getPremmison();
    }
  }, [userInfo]);

  return (
    <div className="Allbackground">
      <DetailButtons applications_id={applications_id} />
      <Schedule applications_id={applications_id} />
      <DetailButtons applications_id={applications_id} />
    </div>
  );
}
